import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import React, { ReactNode } from 'react';
import { CFAIcon, CInfoIcon } from '../Icons';
import { Colors, FontSizes, Spacing } from '../ChakraTheme';

import { CRow } from '../Layouts';
import { CIconButton } from '../Buttons';
import { CFormLabel } from '../Typography';

export const CTooltipBase = ({
    label,
    children,
    ...rest
}: TooltipProps): JSX.Element => {
    return (
        <ChakraTooltip label={label} {...rest}>
            {children}
        </ChakraTooltip>
    );
};

export const CIconTooltip = ({
    label,
    children,
    ...rest
}: Omit<TooltipProps, 'children'> & {
    children?: JSX.Element | ReactNode;
}): JSX.Element => {
    return (
        <CRow noBreak alignItems="center" width="unset" gap={Spacing.XS}>
            {children}
            <CTooltipBase label={label} {...rest}>
                <CInfoIcon
                    height={FontSizes.md}
                    width={FontSizes.md}
                    fill={Colors.grayDark}
                />
            </CTooltipBase>
        </CRow>
    );
};

export const CIconFormLabelTooltip = ({
    label,
    formLabel,
    ...rest
}: Omit<TooltipProps, 'children'> & {
    label: string;
    formLabel: string;
}): JSX.Element => {
    return (
        <CRow noBreak alignItems="baseline" width="unset" gap={Spacing.XS}>
            <CFormLabel whiteSpace="nowrap" label={formLabel} />
            <CTooltipBase label={label} {...rest}>
                <CInfoIcon
                    height={FontSizes.sm}
                    width={FontSizes.sm}
                    fill={Colors.grayDark}
                    mt={Spacing.XXS}
                />
            </CTooltipBase>
        </CRow>
    );
};

export const CButtonIconTooltip = ({
    label,
    onClick,
    testId,
    icon,
    ...rest
}: Omit<TooltipProps, 'children'> & {
    testId: string;
    onClick: () => void;
    icon: IconDefinition;
}): JSX.Element => {
    return (
        <CRow alignItems="baseline">
            <CTooltipBase
                shouldWrapChildren
                placement="top"
                label={label}
                hasArrow
                {...rest}
            >
                <CIconButton
                    color={Colors.black}
                    onClick={onClick}
                    testId={testId}
                >
                    <CFAIcon height="10px" icon={icon} />
                </CIconButton>
            </CTooltipBase>
        </CRow>
    );
};
