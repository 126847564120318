/* eslint-disable react/display-name */
import { Button } from '@chakra-ui/react';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import {
    CBackIcon,
    CCloseIcon,
    CDownloadIcon,
    CFAIcon,
    CMinusIcon,
    CPencilIcon,
    CPlusIcon,
    CSaveIcon,
    CTrashIcon,
} from 'app/koddi-components/src/_ChakraComponents/Icons';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ChakraSizes, Colors, Spacing } from '../ChakraTheme';
import { ButtonBaseProps, LinkButtonProps } from './Button.type';

const ButtonBase = ({
    route,
    testId,
    onClick,
    ...props
}: ButtonBaseProps): JSX.Element => {
    const history = useHistory();
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (route) {
                history?.push(route);
            }
            onClick?.(event);
        },
        [onClick, history, route]
    );
    return <Button {...props} onClick={handleClick} data-test={`${testId}`} />;
};

export const ButtonRefBase = React.forwardRef<
    HTMLButtonElement,
    ButtonBaseProps
>(({ route, testId, onClick, ...props }: ButtonBaseProps, ref) => {
    const history = useHistory();
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (route) {
                history?.push(route);
            }
            onClick?.(event);
        },
        [onClick, history, route]
    );
    return (
        <Button
            {...props}
            onClick={handleClick}
            data-test={`${testId}`}
            ref={ref}
        />
    );
});

export const CButtonWrapper = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => (
    <ButtonBase {...props} variant="wrapper">
        {children}
    </ButtonBase>
);

export const CTextButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="link">
            {children}
        </ButtonBase>
    );
};

export const CLinkButton = ({
    children,
    ...props
}: LinkButtonProps): JSX.Element => {
    return (
        <ButtonBase
            {...props}
            variant="link"
            as={props?.href ? 'a' : undefined}
        >
            {children}
        </ButtonBase>
    );
};

export const CPrimaryButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="primary">
            {children}
        </ButtonBase>
    );
};

export const CSecondaryButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="secondary">
            {children}
        </ButtonBase>
    );
};

export const CBorderButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="border">
            {children}
        </ButtonBase>
    );
};

export const CSubtleButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="subtle">
            {children}
        </ButtonBase>
    );
};

export const CGhostButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="ghost">
            {children}
        </ButtonBase>
    );
};

export const CIconButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="icon">
            {children}
        </ButtonBase>
    );
};

export const CEditButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CIconButton {...props}>
        <CPencilIcon height="80%" />
    </CIconButton>
);

export const CDeleteButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CIconButton {...props}>
        <CTrashIcon height="75%" />
    </CIconButton>
);

export const CSaveButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CIconButton {...props}>
        <CSaveIcon />
    </CIconButton>
);

export const CDownloadButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CIconButton {...props}>
        <CDownloadIcon />
    </CIconButton>
);

export const CPlusButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <ButtonBase {...props}>
        <CPlusIcon fill={Colors.white} />
    </ButtonBase>
);

export const CStackedButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="stacked">
            {children}
        </ButtonBase>
    );
};

export const COutlineButton = ({
    children,
    ...props
}: ButtonBaseProps): JSX.Element => {
    return (
        <ButtonBase {...props} variant="outline">
            {children}
        </ButtonBase>
    );
};

export const CSuccessButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CBorderButton
        {...props}
        borderColor={Colors.success}
        color={Colors.success}
        fill={Colors.success}
        _hover={{
            borderColor: Colors.successDark,
            color: Colors.successDark,
            backgroundColor: Colors.successLight,
        }}
    />
);
export const CErrorButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CBorderButton
        {...props}
        borderColor={Colors.error}
        color={Colors.error}
        fill={Colors.error}
        _hover={{
            borderColor: Colors.errorDark,
            color: Colors.errorDark,
            backgroundColor: Colors.errorLight,
        }}
    />
);

export const CAddButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CSuccessButton
        {...props}
        py={Spacing.XS}
        px={Spacing.SM}
        size={ChakraSizes.sm}
        rightIcon={<CPlusIcon height="8px" />}
    >
        Add
    </CSuccessButton>
);

export const CRemoveButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CErrorButton
        {...props}
        py={Spacing.XS}
        px={Spacing.SM}
        size={ChakraSizes.sm}
        rightIcon={<CMinusIcon height="8px" />}
    >
        Remove
    </CErrorButton>
);

export const CBackButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CIconButton {...props}>
        <CBackIcon height="75%" />
    </CIconButton>
);

export const CCloseButton = ({ ...props }: ButtonBaseProps): JSX.Element => (
    <CIconButton {...props}>
        <CCloseIcon height="75%" />
    </CIconButton>
);

export const CElipsisButton = React.forwardRef<
    HTMLButtonElement,
    ButtonBaseProps
>(({ ...props }, ref) => {
    return (
        <ButtonRefBase {...props} ref={ref} variant="icon">
            <CFAIcon icon={faEllipsisVertical} />
        </ButtonRefBase>
    );
});
