import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const CDV360Icon = createIcon({
    displayName: 'CDV360Icon',
    viewBox: '0 0 2192.7 2428.1',
    path: (
        <g xmlns="http://www.w3.org/2000/svg">
            <path
                className="st0"
                fill="#81C995"
                d="M1730.6,949.3c142.5-86.5,328.1-41.1,414.5,101.4c86.5,142.5,41.1,328.1-101.4,414.5   c-3.8,2.3-7.6,4.5-11.4,6.6L460.2,2377.1c-142.5,86.5-328.1,41.1-414.6-101.4c-86.5-142.5-41.1-328.1,101.4-414.6   c3.8-2.3,7.6-4.5,11.4-6.6L1730.6,949.3z"
            />
            <path
                className="st1"
                fill="#34A853"
                d="M601,303.7C601.7,137.4,467.4,1.9,301.1,1.2c-21.4-0.1-42.7,2.1-63.7,6.5C96.5,42.3-2,169.5,0,314.7v1798.1   l600.4,10.9L601,303.7z"
            />
            <path
                className="st2"
                fill="#5BB974"
                d="M1737.4,1472.9c145.3,75.3,324.1,27.1,411.9-111.1c84.4-142,37.7-325.6-104.3-410c-1.1-0.6-2.1-1.2-3.2-1.9   L459.2,43C318.3-42.1,135,3.1,49.8,144c-2.1,3.5-4.2,7.1-6.2,10.7C-40,297.8,8.2,481.7,151.3,565.4c1,0.6,2,1.1,3,1.7   L1737.4,1472.9z"
            />
            <path
                className="st0"
                fill="#81C995"
                d="M2031.4,1475L600.5,2274.3v-686.6l1135.4-633.1c150.5-83.4,340.2-29,423.6,121.6c7.8,14,14.4,28.6,19.9,43.6   C2222,1258.3,2159.8,1407.6,2031.4,1475z"
            />
            <circle
                className="st3"
                fill="#1E8E3E"
                cx="300.8"
                cy="2127.8"
                r="300.2"
            />
        </g>
    ),
});

export const CTradeDeskIcon = createIcon({
    displayName: 'CTradeDeskIcon',
    viewBox: '0 0 1508 1589',
    path: (
        <g xmlns="http://www.w3.org/2000/svg" id="Layer">
            <g id="Layer">
                <path
                    id="Layer"
                    fill="#0099fa"
                    d="m875.7 0.4v243.6 649.7h-244.1v-645.9h-253.5v-247.4z"
                />
                <g id="Layer">
                    <path
                        id="Layer"
                        fill="#0099fa"
                        d="m514.3 1285.4v264.4c-298.9-99.9-514.3-381.6-514.3-713.5 0-167.4 54.8-321.8 147.4-446.7h362.1v0.1c-158.1 86.3-265.4 253.9-265.4 446.6 0 194.5 109.5 363.5 270.2 449.1z"
                    />
                    <path
                        id="Layer"
                        fill="#0099fa"
                        d="m1507.4 836.3c0 415.5-337.5 752.3-753.7 752.3-41.5 0-82.1-3.4-121.8-9.8v-248.5c39 9.5 79.8 14.6 121.8 14.6 281.4 0 509.7-227.7 509.7-508.6 0-192.7-107.4-360.3-265.6-446.7v-265.1c296.4 101.2 509.6 381.5 509.6 711.8z"
                    />
                </g>
            </g>
        </g>
    ),
});

export const CGoogleShoppingIcon = createIcon({
    displayName: 'CGoogleShoppingIcon',
    viewBox: '.381 -4.99021715 516.22889793 516.22821715',
    path: (
        <g fill="none" fillRule="evenodd">
            <path
                d="m411.503 136.66c20.182 0 36.545-16.362 36.545-36.544s-16.363-36.544-36.545-36.544-36.544 16.362-36.544 36.544 16.362 36.544 36.544 36.544zm-258.888-11.155 112.108-112.108c8.552-8.552 20.428-13.777 33.493-13.777h166.276a47.343 47.343 0 0 1 47.508 47.507v166.276c0 13.065-5.226 24.942-14.015 33.493l-111.937 112.044z"
                fill="#4285f4"
            />
            <path
                d="m386.048 358.94-138.153 138.283c-8.79 8.551-20.666 14.015-33.73 14.015s-24.942-5.464-33.493-14.015l-166.277-166.275c-8.789-8.552-14.014-20.428-14.014-33.493 0-13.302 5.463-25.179 14.014-33.73l138.22-138.22z"
                fill="#34a853"
            />
            <path
                d="m95.085 411.637-80.69-80.69c-8.789-8.549-14.014-20.427-14.014-33.491 0-13.302 5.463-25.179 14.014-33.73l138.22-138.22 114.3 114.302z"
                fill="#fbbc05"
            />
            <path
                d="m495.595 11.127a47.344 47.344 0 0 0 -31.103-11.507h-166.276c-13.065 0-24.941 5.225-33.493 13.777l-112.108 112.108 114.3 114.302 116.415-116.415a36.394 36.394 0 0 1 -8.37-23.276c0-20.182 16.361-36.544 36.543-36.544a36.394 36.394 0 0 1 23.277 8.37z"
                fill="#ea4335"
            />
            <circle cx="266.849" cy="252.764" fill="#4285f4" r="74.23" />
            <path
                d="m367.983 230.928h-98.726v42.358h56.828c-5.298 26.91-27.45 42.358-56.828 42.358-34.675 0-62.607-28.903-62.607-64.783s27.932-64.783 62.607-64.783c14.93 0 28.414 5.482 39.009 14.452l30.822-31.894c-18.782-16.943-42.862-27.408-69.831-27.408-58.754 0-105.95 48.837-105.95 109.633s47.196 109.633 105.95 109.633c52.975 0 101.134-39.867 101.134-109.633 0-6.478-.963-13.455-2.408-19.933z"
                fill="#fff"
            />
        </g>
    ),
});

export const CGoogleAdManagerIcon = createIcon({
    displayName: 'CGoogleAdManagerIcon',
    viewBox: '0 0 2454.3 2454.3',
    path: (
        <g>
            <path
                className="st0"
                fill="#FBBC04"
                d="M2385.8,1385L1385,2385.8c-91.3,91.3-239.4,91.3-330.7,0c-91.4-91.3-91.3-239.4,0-330.7l1000.9-1000.9   c91.3-91.3,239.4-91.3,330.7,0C2477.2,1145.6,2477.2,1293.6,2385.8,1385L2385.8,1385z"
            />
            <ellipse
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1212.9008 1512.7477)"
                className="st1"
                fill="#34A853"
                cx="1219.6"
                cy="2220.5"
                rx="233.9"
                ry="233.9"
            />
            <path
                className="st0"
                fill="#FBBC04"
                d="M1562.3,561.4l-500.5,500.4l330.7,330.7L1893,892.1c91.3-91.3,91.3-239.4,0-330.7   C1801.6,470,1653.6,470,1562.3,561.4L1562.3,561.4z"
            />
            <path
                className="st2"
                fill="#3B80EF"
                d="M892.1,1893l500.4-500.4l-330.7-330.7l-500.4,500.4c-91.3,91.3-91.3,239.4,0,330.7   C652.7,1984.3,800.8,1984.3,892.1,1893L892.1,1893z"
            />
            <ellipse
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -507.2274 1227.6238)"
                className="st1"
                fill="#34A853"
                cx="1228.3"
                cy="1226.1"
                rx="233.9"
                ry="233.9"
            />
            <path
                className="st2"
                fill="#3B80EF"
                d="M1400.1,399.2L399.2,1400.1c-91.3,91.3-239.4,91.3-330.7,0c-91.3-91.3-91.3-239.4,0-330.7L1069.4,68.5   c91.3-91.3,239.4-91.3,330.7,0C1491.4,159.8,1491.4,307.9,1400.1,399.2L1400.1,399.2z"
            />
            <circle
                className="st1"
                fill="#34A853"
                cx="1234.7"
                cy="233.9"
                r="233.9"
            />
        </g>
    ),
});
