import React from 'react';
import { Box } from '@chakra-ui/react';
import { Shadows } from '../ChakraTheme';
import { Border, BorderWidth } from '../ChakraTheme/configs/Borders.config';
import { Colors } from '../ChakraTheme/configs/Colors.config';
import { Spacing } from '../ChakraTheme/configs/Spacing.config';
import { CRow } from '../Layouts';
import { CardBaseProps } from './Cards.type';

const CCardBase = ({ children, ...props }: CardBaseProps): JSX.Element => (
    <Box
        borderRadius={Border.Radius.SM}
        borderStyle="solid"
        p={Spacing.SM}
        wordBreak="break-word"
        {...props}
    >
        {children}
    </Box>
);

export const CCardHeader = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CRow
        alignItems="center"
        borderBottom={`${BorderWidth.Thin} solid`}
        borderBottomColor={Colors.grayLightest}
        pb={Spacing.SM}
        mb={Spacing.SM}
        {...props}
    >
        {children}
    </CRow>
);

export const CGrayCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase {...props} bgColor={Colors.cardGray}>
        {children}
    </CCardBase>
);

export const CWhiteCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        p={Spacing.MD}
        bgColor={Colors.white}
        borderColor={Colors.grayLight}
        borderWidth={BorderWidth.Thinnest}
        {...props}
    >
        {children}
    </CCardBase>
);

export const CKpiCard = ({
    children,
    color = 'red',
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        p={Spacing.MD}
        bgColor={Colors.white}
        borderColor={Colors.grayLight}
        borderWidth={BorderWidth.Thinnest}
        borderBottomColor={color}
        borderBottomWidth={5}
        borderRadius={Border.Radius.MD}
        {...props}
    >
        {children}
    </CCardBase>
);

export const CShadowedCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        p={Spacing.MD}
        bgColor={Colors.white}
        borderColor="transparent"
        borderWidth={BorderWidth.Thinnest}
        boxShadow={Shadows.BASE}
        {...props}
    >
        {children}
    </CCardBase>
);

export const CBorderedCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        border={Border.Width.Thin}
        borderColor={Colors.primary}
        bg={Colors.white}
    >
        {children}
    </CCardBase>
);

export const CBorderedGrayCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        border={Border.Width.Thin}
        borderColor={Colors.grayLight}
        bgColor={Colors.grayLightest}
    >
        {children}
    </CCardBase>
);

// TODO:  come back to cards and convert border and white card to base variants

export const CBorderedSuccessCard = ({
    children,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        p={Spacing.SM}
        border={Border.Width.Thin}
        borderColor={Colors.success}
    >
        {children}
    </CCardBase>
);

export const CSplitCard = ({
    splitSlot,
    isLeft = true,
    children,
    ...props
}: CardBaseProps & {
    splitSlot?: JSX.Element;
    isLeft?: boolean;
}): JSX.Element => (
    <CCardBase
        {...props}
        w="100%"
        p={0}
        border={BorderWidth.Thinnest}
        borderColor={Colors.grayLight}
        bg={Colors.white}
        display="flex"
        boxShadow={Shadows.SMALL}
        flexDirection={isLeft ? 'row' : 'row-reverse'}
    >
        <Box
            display="flex"
            bg={Colors.grayLightest}
            flex={1}
            w="100%"
            justifyContent="center"
            alignItems="center"
            minW="200px"
        >
            {splitSlot}
        </Box>
        <Box flex={3}>{children}</Box>
    </CCardBase>
);

export const CBottomAccentBorderedCard = ({
    children,
    borderBottomColor,
    ...props
}: CardBaseProps): JSX.Element => (
    <CCardBase
        {...props}
        border={Border.Width.Thin}
        borderColor={Colors.grayLight}
        borderBottomColor={borderBottomColor}
        bgColor={Colors.white}
    >
        {children}
    </CCardBase>
);
