import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { TabData, TabsProps } from './Tabs.types';

export const useTabs = ({
    tabs,
    onChange,
    defaultTabId,
    shouldUpdateUrl,
}: TabsProps): {
    handleChange: (v: number) => void;
    tabIndex: number;
    visibleTabs: TabData[];
} => {
    const visibleTabs = tabs.filter((tab: TabData) => tab.canView);
    const tabIds = visibleTabs.map((tab: TabData) => tab.id);
    const history = useHistory();
    const { pathname, search } = useLocation();
    const query = new URLSearchParams(search);
    const tabByURL = query.get('tab');
    const [tabIndex, setTabIndex] = useState<number>();

    const keyOfLegacyRoute = useMemo(() => {
        const pathNameParts = history.location.pathname.split('/');
        let lastPath;
        let i = 0;
        while (!lastPath && i < 2) {
            lastPath = pathNameParts.pop();
            i += 1;
        }
        return lastPath;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateURL = useCallback(
        (tab: string) => {
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.forEach((_, key) => {
                if (key !== 'tab') {
                    searchParams.delete(key);
                }
            });
            searchParams.set('tab', tab);

            history.push({
                pathname,
                search: searchParams.toString(),
                hash: history.location.hash,
            });
        },
        [history, pathname]
    );
    const handleChange = useCallback(
        (index: number) => {
            onChange?.(index);
            if (shouldUpdateUrl) updateURL(tabIds[index]);
            else setTabIndex(index);
        },
        [onChange, tabIds, updateURL, shouldUpdateUrl]
    );

    const getIndex = useCallback(
        (id: string) => {
            const index = tabIds.indexOf(id);
            if (index >= 0) {
                return index;
            }
            const defaultIndex = tabIds.indexOf(defaultTabId ?? '');
            if (defaultIndex >= 0) {
                return defaultIndex;
            }
            return 0;
        },
        [defaultTabId, tabIds]
    );

    useEffect(() => {
        if (!shouldUpdateUrl) return undefined;
        if (!tabByURL && keyOfLegacyRoute) {
            return setTabIndex(getIndex(keyOfLegacyRoute));
        }
        if (tabByURL && tabIds.includes(tabByURL)) {
            return setTabIndex(getIndex(tabByURL));
        }
        if (defaultTabId && tabIds.includes(defaultTabId)) {
            return setTabIndex(getIndex(defaultTabId));
        }
        return setTabIndex(0);
    }, [
        tabByURL,
        tabIds,
        defaultTabId,
        keyOfLegacyRoute,
        getIndex,
        shouldUpdateUrl,
    ]);

    return {
        handleChange,
        tabIndex: tabIndex || 0,
        visibleTabs,
    };
};
