import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { CFAIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import {
    Colors,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import React from 'react';

export const DropdownIndicator = (): JSX.Element => {
    return (
        <CRow
            justifyContent="space-between"
            alignItems="center"
            ml={Spacing.XXXS}
            px={Spacing.SM}
            width="125px"
            color={Colors.grayDark}
            pb={Spacing.XXXS}
        >
            Select
            <CFAIcon icon={faChevronDown} />
        </CRow>
    );
};
