import {
    CampaignStatus,
    CampaignBudgetType,
    CampaignGoalType,
    CampaignPacing,
} from './Campaigns.types';

export const CAMPAIGN_STATUS: Record<CampaignStatus, CampaignStatus> = {
    active: 'active',
    paused: 'paused',
    pending: 'pending',
    ended: 'ended',
    inactive: 'inactive',
};

export const CAMPAIGN_BUDGET_TYPE: Record<
    CampaignBudgetType,
    CampaignBudgetType
> = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Custom: 'Custom',
};

export const CAMPAIGN_GOAL_TYPE: Record<CampaignGoalType, CampaignGoalType> = {
    ROAS: 'ROAS',
};

export const CAMPAIGN_PACING: Record<CampaignPacing, CampaignPacing> = {
    ASAP: 'ASAP',
    Evenly: 'Evenly',
};
