import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';

import { CFAIcon } from 'app/koddi-components/src/_ChakraComponents/Icons';
import { CRow } from 'app/koddi-components/src/_ChakraComponents/Layouts';
import {
    Colors,
    Spacing,
} from 'app/koddi-components/src/_ChakraComponents/ChakraTheme';
import React from 'react';
import { InputProps, components as ReactSelectComponents } from 'react-select';

export const IconSearchInput = (props: InputProps): JSX.Element => {
    /* @ts-ignore */ // outdated types creating issues... selectProps is in fact part of the props
    const { selectProps } = props;
    const innerProps = {
        ...props,
        placeholder: selectProps?.inputId ?? 'Search',
    };
    return (
        <CRow
            gap={Spacing.XXS}
            noBreak
            alignItems="center"
            width="inherit"
            pb="2px"
        >
            <CFAIcon icon={faCalendarAlt} fill={Colors.grayDark} />
            <ReactSelectComponents.Input {...innerProps} />
        </CRow>
    );
};
