import { AdGroup } from 'app/react-ui/api/AdGroups';
import { BidType, ExperienceDetails } from 'app/react-ui/api/Admin/types';
import {
    Asset,
    InsertionOrderConfig,
} from 'app/react-ui/api/Admin/MemberGroup/MemberGroup.types';
import {
    // SimpleCampaignEntitySelection,
    AssetFormValue,
    SimpleCampaignEntitySelection,
} from 'app/react-ui/api/Advertiser/Campaigns';
// import { CampaignPacing, CampaignReviewData } from 'app/react-ui/api/Campaigns';
import {
    CampaignPacing,
    CampaignReviewData,
    CampaignStatus,
} from 'app/react-ui/api/Campaigns';
import {
    CalculatedTargetingLimits,
    ExperienceTargetingDimensionsType,
} from 'app/react-ui/api/Admin/TargetingConfiguration';
import { OptionType } from 'app/koddi-components/src/_ChakraComponents/Forms/Types';
import { BudgetType } from 'app/react-ui/api/KoddiRecommendations/KoddiRecommendations.types';
import { Audience } from 'app/react-ui/api/Offsite/Audiences/Audiences.types';
import { FormattedAttribute } from './CampaignWizardSteps/CustomCampaignSteps/AdGroupStep/AdGroupStepSections/AttributionSection/AttributionSection.types';

export type CampaignWizardTargetingHookProps = {
    memberGroupId?: string;
};

export enum CampaignWizardStepKeys {
    // @deprecated
    BudgetStep = 'budget-step',
    ReviewStep = 'step-review',

    // shared
    StepOne = 'step-1',

    // custom
    CustomBasicInfo = 'step-basic-info',
    CustomAdGroups = 'step-ad-group',
    CustomReviewStep = 'custom-review',

    // templated
    TemplatedBasicInfo = 'step-2',
    TemplatedEntitySelection = 'step-3',
    TemplatedAttribution = 'step-attribution',
    TemplatedReviewStep = 'templated-review',
    TemplatedAssets = 'step-asset',
}

export type AdGroupForDraftInitialValues = Partial<AdGroup> &
    Record<string, unknown> & {
        assets?: AssetFormValue;
    };

export type TemplateDetails = {
    campaign_template_id: number;
    member_group_id: number;
    name: string;
    description: string;
    is_recommended: boolean;
    order: number;
    expiration_date: string | null;
    status: string;
    budget_type: string;
    pacing: string;
    is_auto_bid: boolean;
    has_campaign_with_same_name: number;
    base_bid: {
        type: string;
        amount: number;
    };
    primary_goal: {
        type: string;
        value: string;
    };
    targeting: any;
    experience: {
        id: number;
        name: string;
    };
};

export type TargetingForCampaigns = {
    targetingOptions: ExperienceTargetingDimensionsType[] | null;
    targetingOperators: OptionType<string>[] | null;
    calculatedTargetingLimits: CalculatedTargetingLimits | null;
} | null;

export type CampaignType = {
    templateDetails: TemplateDetails | null;
    experienceDetails: ExperienceDetails | null;
    isCustomCampaign: boolean;
    assets: Asset[] | null;
    attributes: FormattedAttribute[] | null;
    targeting: TargetingForCampaigns;
    clientInsertionOrderDetails?: InsertionOrderConfig;
};

export type AdGroupData = {
    id: number;
    campaign_id: number;
    name: string;
    start_date: string;
    end_date: string | null;
    always_on: boolean;
    is_auto_bid: boolean;
    budget: {
        type: BudgetType;
        amount: number;
    };
    status: CampaignStatus;
    base_bid: BidType;
    effective_status_id: null;
    targeting: null;
    bid_strength: number;
    campaign_template_id: null;
    experience_id: number;
    asset_associations: null;
    attribute_key_values: [];
    targeting_automation_enabled: false;
    metadata: Record<string, unknown>;
    audiences: Audience[];
};
export type CampaignData = {
    name?: string;
    start_date: string;
    end_date: string | null;
    always_on: boolean;
    budget_type: string;
    budget: number | null;
    pcl_value: string;
    insertion_order: null;
    attribute_key_values: null | number[];
    is_auto_bid?: boolean;
    enable_frequency_capping?: boolean;
    frequency_capping?: string;
    pacing?: CampaignPacing;
    primary_goal?: {
        type: string;
        value?: string;
    };
    status?: string;
    campaignId?: number;
    experience_id?: number;
    ad_groups?: AdGroupData[];
    entity_selection?: SimpleCampaignEntitySelection;
    assets?: AssetFormValue;
    ad_group_id?: number;
    review_status?: CampaignReviewData;
};

export type CMTFormValues = any;
